<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="switch-cover">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="bg-white p-5 auth-card">
              <form method="post" action="#" v-on:submit.prevent="validateLogin">
                <div class="mx-auto">
                  <div>
                    <h4>Welcome</h4>
                  </div>

                  <div class=" mb-5">
                    <h4 style="color:#000;font-weight: bolder;">Login to continue</h4>
                  </div>

                  <div class="form-group mb-3">
                    <label :class="{ 'text-danger': $v.user.email.$error }"><strong>Email</strong> </label> <br/>
                    <input v-model.trim="$v.user.email.$model" class="form-control" type="text" placeholder="Email" />
                    <div class="text-danger" v-if="!$v.user.email.required && $v.user.email.$dirty">Email is required</div>
                  </div>

                  <div class="form-group mb-3">
                    <label :class="{ 'text-danger': $v.user.password.$error }"><strong>Password</strong> </label> <br/>
                    <input v-model.trim="$v.user.password.$model"  class="form-control" type="password"  />
                    <div class="text-danger" v-if="!$v.user.password.required && $v.user.password.$dirty" >Password is required</div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label> <input type="checkbox" v-model="user.rememberMe">   Remember Me</label>
                    </div>

                    <div class="col-md-6">
                      <RouterLink style="float: right;color: #000;"  to="/forgot-password">Forgot Password ?</RouterLink>
                    </div>
                  </div>

                  <div class="mt-5 text-center">
                    <button type="submit" style="background-color: #E42B1A !important;color:#fff;"  class=" btn-secondary btn  btn-lg  quote-btn btn-block">  <strong>Login </strong>  </button>
                  </div>

                  <div class="mt-2 " style="color: #BDBDBD;font-size: 12px;">
                    <div class="text-center">
                      <span >Don’t have an account ? <RouterLink style="font-weight: bolder;color:#E42B1A;" to="/sign-up"> Sign Up </RouterLink> </span>
                    </div>
                  </div>

                </div>

              </form>
            </div>

          </div>
          <div class="col-md-7 " >
          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>

  import { required} from 'vuelidate/lib/validators'
  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import firebase from 'firebase/app'
  import 'firebase/auth'


  export default {
    name: 'home',
    data () {
      return {
        isLoading: false,
        fullPage: true,
        user:{
          email: "",
          password: "",
          rememberMe:""
        }
      };
    },
    mounted(){
      console.log(process.env.VUE_APP_ENV);
    },

    components: { Loading },

    validations: {
      user:{
        email:{required},
        password:{required}
      },
    },

    methods:{
      ...mapActions(["generateToken"]),
      loginUser(){


        let self = this;
        this.isLoading = true;
        firebase.auth().signInWithEmailAndPassword(this.user.email, this.user.password)
                .then((userCredential) => {
                  firebase.auth().currentUser.getIdToken().then((idToken) => {

                    self.isLoading = false;

                    self.$toast.open({
                      message: "Success! You are now logged in!",
                      type: 'success',
                      duration:5000,
                      position:"top-right"
                    });

                    // Signed in
                    self.getUserDetails(idToken);
                  })
                  .catch((error) => {

                    self.isLoading = false;

                    self.$toast.open({
                      message: "Error validating user credentials",
                      type: 'error',
                      duration:5000,
                      position:"top-right"
                    });

                    console.log(error);

                    const errorCode = error.code;
                    const errorMessage = error.message;
                  })
                })
                .catch((error) => {
                  self.isLoading = false;

                  self.$toast.open({
                    message: "Wrong email and password combination",
                    type: 'error',
                    duration:5000,
                    position:"top-right"
                  });

                  console.log(error);

                  const errorCode = error.code;
                  const errorMessage = error.message;
                });
      },
      getUserDetails(idToken){
        let self = this;

        this.isLoading = true;

        this.generateToken({
          email: this.user.email,
          remember_me: true,
          idToken
        }).then((userDetails) => {
          self.isLoading = false;

         localStorage.setItem("token", userDetails.token)

          localStorage.setItem("email", userDetails.user.email)
          localStorage.setItem("middle_name", userDetails.user.middle_name)
          localStorage.setItem("name", userDetails.user.name)
          localStorage.setItem("national_id", userDetails.user.token)
          localStorage.setItem("phone", userDetails.user.phone)
          localStorage.setItem("surname", userDetails.user.surname)
          localStorage.setItem("_id", userDetails.user._id)

          self.$router.push("/app/home");

        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      },

      validateLogin(){


        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.loginUser()
        }
      },
      goBack(){
        this.$router.push('/quote');
      },

    }
  }
</script>

<style>

  table td{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .personal-info{
    min-height: 80vh;
    background-color: #F2F2F2;
  }


  .sso-sign-up a{
    border:1px solid #BDBDBD;
    padding: 15px;
    margin: 5px;
    border-radius: 4px;
  }
  .sso-sign-up img{
      height: 30px;
      width: 30px;
  }
   .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #666666;
    font-size: 16px;
    font-weight: 600;
  }
  .nav-link:hover {
    border: none;
  }

  .personal-info button{
    width: 100%;
  }


</style>
